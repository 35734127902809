<template>
  <div>
    <div class="ll-box d-flex">
      <!-- breadcrumb -->
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <feather-icon
              icon="HomeIcon"
              size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('Fulfillment') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active >
            ASN
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <!-- button -->
      <b-row class="mb-1 justify-content-end">
        <b-col cols="2" class="d-flex justify-content-end">
          <b-button
            variant="primary"
            class="mr-1"
            v-b-modal.modal-upload
          >
            Upload
          </b-button>
        </b-col>
      </b-row>
    </div>
    <!-- Filters -->
    <b-row class="mb-1">
      <b-col cols="2">
        <b-input-group class="input-group-merge ml-1 w-100">
          <b-input-group-prepend is-text>
            <feather-icon
              icon="SearchIcon"
              class="text-muted"
            />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Search..."
            v-b-modal.modal-search
          />
        </b-input-group>
      </b-col>
      <b-col cols="2">
        <v-select
          v-model="status"
          :options="statusList"
          label="codeDescription"
          @input="selStatus"
          placeholder="Status"
        ></v-select>
      </b-col>
      <b-col cols="2">
        <el-date-picker
          v-model="time"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="-"
          :picker-options="pickerOptions"
          size="small"
          class="ll-datePicker"
          @input="onDateHandle"
        >
        </el-date-picker>
      </b-col>
      <b-col cols="2">
        <b-button
          variant="info"
          class="round mr-1"
          @click="getList"
        >
          {{ $t('Search') }}
        </b-button>
        <b-button
          variant="secondary"
          class="round"
          v-show="isResetBtn"
          @click="onReset"
        >
          {{ $t('Reset') }}
        </b-button>
      </b-col>
      <b-col cols="4" class="d-flex justify-content-end">
        <b-button
         variant="warning"
         v-b-modal.modal-export
         v-if="export1"
        >
          Export
        </b-button>
        <b-dropdown
          text="Export"
          variant="warning"
          v-b-modal.modal-exportPro
          v-if="export2"
          class="ll-dropdown"
        >
          <b-dropdown-item>Export new report<br>Check export status</b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- status -->
    <b-badge
      pill
      class="ll-badge mb-1 mr-1 cursor-pointer"
      variant="light-info"
      v-for="(item, index) in paymentStatusList"
      :key="index"
      @click="onStatusSearch(item.name)"
    >
      {{(item.name !== null) ? item.name.replace(/_/g, ' ') : null}}
      <span class="ll-count">{{item.count}}</span>
    </b-badge>

    <!-- table -->
    <el-table
      :data="tableData"
      stripe
      size="small"
      ref="table"
      :empty-text="$t('No matching records found')"
      @filter-change="filterTHeader"
      @sort-change="onSortChange"
      @selection-change="handleSelection"
    >
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <template v-for="(item, index) in tableHeaders">
        <el-table-column
          v-if="item.prop == 'isOrderWithIssue'"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template slot-scope>
            <svg aria-hidden="true"
              class="ll-normal1"
            >
              <use xlink:href="#icon-Return"></use>
            </svg>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.prop == 'orderCreationTime'"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
          sortable
        >
          <template slot-scope="scope">
            {{scope.row.orderCreationTime}}
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.prop == 'orderNo'"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
          sortable
        >
          <template slot-scope="scope">
            <b-link
              class="nav-link"
              @click="onOrderDetail(scope.row.id)"
              style="padding-left: 0px;"
            >
            {{scope.row.orderNo}}
            </b-link>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.prop == 'Actions'"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="scope.row" v-b-modal.modal-orderDetail>
                  <feather-icon icon="EyeIcon" />
                  <span class="align-middle ml-50">View</span>
                </el-dropdown-item>
                <el-dropdown-item v-b-modal.modal-del :command="scope.row">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Cancel</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :column-key="item.prop"
          :min-width="item.minWidth"
          :width="item.width"
          show-overflow-tooltip
        >
        </el-table-column>
      </template>
      <!-- 过滤 -->
      <el-table-column
        width="60"
        align="center"
        :filters="filters"
        filter-placement=""
        column-key="filter"
      >
        <template slot="header">
          <svg aria-hidden="true" class="ll-icon">
            <use xlink:href="#icon-filter1"></use>
          </svg>
        </template>
      </el-table-column>
    </el-table>
    <div class="d-flex justify-content-center flex-wrap ll-page">
      <div class="d-flex align-items-center mb-0 mt-1 mr-1">
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> item per page </span>
      </div>
      <div class="mt-1">
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
    <b-modal
      id="modal-search"
      cancel-variant="outline-secondary"
      ok-title="Search"
      centered
      title="Search"
    >
      <b-form>
        <b-form-group>
          <label >ASN #:</label>
          <b-form-input v-model="condition.orderNumber"/>
        </b-form-group>
        <b-form-group>
          <label >{{$t('Customer')}}:</label>
          <!-- <b-form-input v-model="condition.clientCode"/> -->
          <v-select></v-select>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="primary"
          class="btn"
          @click="getList"
        >
          {{ $t('Search') }}
        </b-button>
      </template>
    </b-modal>
    <!-- upload -->
    <b-modal
      id="modal-upload"
      cancel-variant="outline-secondary"
      ok-title="Sure"
      centered
      title=""
      v-model="show"
    >
      <el-upload
        class="upload-demo ll-upload"
        drag
        action="https://jsonplaceholder.typicode.com/posts/"
        multiple
        accept=".csv,.xlsx"
        :on-success="uploadSuccess"
        :on-error="uploadFail"
        :on-progress="uploadPro">
        <!-- <i class="el-icon-upload"></i> -->
        <div class="el-upload__text" v-if="isDefault">
          {{uploadText}}
        </div>
        <div v-if="uLoading" class="el-upload__text">
          <p class="ll-uploadText">Uploading...</p>
          <b-spinner label="Loading..." />
        </div>
        <div v-if="uSuccess" class="el-upload__text">
          <svg aria-hidden="true" class="ll-success ll-uploadIcon">
            <use xlink:href="#icon-form_icon_normal"></use>
          </svg><br>
          <span class="ll-uploadText ll-success">Upload success</span>
        </div>
        <div v-if="uFail" class="el-upload__text ll-upload">
          <svg aria-hidden="true" class="ll-fail ll-uploadIcon">
            <use xlink:href="#icon-delete"></use>
          </svg><br>
          <span class="ll-uploadText ll-fail">Upload failed</span><br>
          <span class="ll-uploadText">ERROR: SKU {XXX} not found</span>
        </div>
        <div class="el-upload__tip" slot="tip">Download template here<br>Only accept CSV,xlsx etc</div>
      </el-upload>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show = false"
          v-if="false"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn"
        >
          {{ $t('Upload') }}
        </b-button>
      </template>
    </b-modal>
    <!-- Export -->
    <b-modal
      id="modal-export"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title=""
      v-model="show1"
    >
      <div class="ll-ulBox">
        <span>Export to:</span>
        <ul>
          <li class="mb-1">
            <b-form-radio
              v-model="selected1"
              name="some-radios"
              value="a"
            >
              CSV
            </b-form-radio>
          </li>
          <li>
            <b-form-radio
              v-model="selected1"
              name="some-radios"
              value="b"
            >
              xlsx
            </b-form-radio>
          </li>
        </ul>
      </div>
      <div class="ll-ulBox">
        <span>Orders:</span>
        <ul>
          <li class="mb-1">
            <b-form-radio
              v-model="selected2"
              name="some-radios2"
              value="c"
            >
              All ASN
            </b-form-radio>
          </li>
          <li>
            <b-form-radio
              v-model="selected2"
              name="some-radios2"
              value="d"
            >
              Selected<br>(Filtered with xxx,xxx,xxx)
            </b-form-radio>
          </li>
        </ul>
      </div>
      <div class="ll-ulBox">
        <span>Type:</span>
        <ul>
          <li class="mb-1">
            <b-form-radio
              v-model="selected3"
              name="some-radios3"
              value="e"
            >
              ASN information
            </b-form-radio>
          </li>
          <li>
            <b-form-radio
              v-model="selected3"
              name="some-radios3"
              value="f"
            >
              ASN with line items details
            </b-form-radio>
          </li>
        </ul>
      </div>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show1 = false"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn"
          @click="exportHandle"
        >
          {{ $t('Export') }}
        </b-button>
      </template>
    </b-modal>
    <!-- Export progress-->
    <b-modal
      id="modal-exportPro"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title=""
      v-model="show2"
      size="xl"
    >
      <b-table :items="items" :fields="fields"
        :borderless='false' :hover="true"
        :sticky-header="true"
        class="ll-table"
      >
        <template #cell(action)="scope">
          <b-button
            class="btn-icon"
            variant="flat-danger"
            @click="del(scope.item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-button
            class="btn-icon"
            variant="flat-info"
            :href="scope.item.reportUrl"
            :disabled="scope.item.reportUrl===null"
            :style="{'cursor': (scope.item.reportUrl===null) ? 'no-drop' : 'pointer'}"
          >
            <feather-icon icon="ArrowDownIcon" />
          </b-button>
        </template>
        <template #cell(progress)="scope">
          <b-progress
            key="info"
            :max="scope.item.maxProgress"
            variant="info"
            class="progress-bar-info"
          >
            <b-progress-bar
              :value="scope.value"
              variant="info"
              :label="`${((scope.item.progress/scope.item.maxProgress) * 100)}%`"
            />
          </b-progress>
        </template>
      </b-table>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show2 = false"
        >
          {{ $t('Cancel') }}
        </b-button>
      </template>
    </b-modal>
    <!-- delete-->
    <b-modal
      id="modal-del"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title=""
      v-model="show3"
    >
      <div class="text-center">
        <svg aria-hidden="true" class="ll-delicon">
          <use xlink:href="#icon-cancel1"></use>
        </svg><p/>
        <p style="font-size:18px;"><b>Cancel ASN</b></p>
        <p>You are cancelling ASN<br>
          <b>This action cannot be undone</b>
        </p>
        <!-- <span>Type 'cancel order' to confirm</span>
        <b-form-input v-model="delOrder"/> -->
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            variant="outline-secondary"
            class="btn mr-2"
            @click="show3 = false"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            variant="primary"
            class="btn"
            @click="show3 = false"
          >
            {{ $t('Confirm') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- Order Detail -->
    <b-modal
      id="modal-orderDetail"
      ref="modal-orderDetail"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title=""
      size="xl"
      v-model="show8"
    >
      <asn-details></asn-details>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="btn"
          @click="show8 = false"
        >
          {{ $t('Close') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButton, BTooltip,
  BCard, BCardBody, BRow, BCol, BSidebar, VBToggle, BTable,
  BInputGroupPrepend, BInputGroup, BBreadcrumb, BBreadcrumbItem,
  BBadge, BSpinner, BDropdown, BDropdownItem, BLink, BModal, VBModal,
  BForm, VBTooltip, BProgress, BProgressBar, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import permission from '@core/directives/permission/index.js'
import asnDetails from './components/asnDetails.vue'

export default {
  components: {
    asnDetails,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BTooltip,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BSidebar,
    vSelect,
    flatPickr,
    BTable,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BLink,
    BModal,
    BForm,
    BProgress,
    BProgressBar,
    BFormRadio,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
    permission,
  },
  props: {
  },
  data() {
    return {
      dateConfig: {
        dateFormat: 'Y-m-d',
        mode: 'range',
        defaultDate: '',
      },
      time: '',
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null,
      size: 50,
      totalSize: null,
      totalPage: null,
      tableData: [
        {orderCreationTime:'2022-01-01',orderNo:'test001',cneeName:'test',datasourceType:'kec'}
      ],
      tableHeaders: [
        { label: '', prop: "isOrderWithIssue", width: "60" },
        { label: this.$t('Create Date'), prop: "orderCreationTime", width: "auto" },
        { label: 'ASN #', prop: "orderNo", width: "250" },
        { label: this.$t('Centre'), prop: "cneeName", width: "auto" },
        { label: this.$t('Customer'), prop: "datasourceType", width: "auto" },
        { label: 'ETA', prop: "totalGoodsValue", width: "auto" },
        { label: 'ATA', prop: "paymentStatus", width: "200" },
        { label: this.$t('Complete Date'), prop: "orderUpdateTime", width: "auto" },
        { label: this.$t('Status'), prop: "status", width: "auto" },
        { label: this.$t('Last Update'), prop: "lastUpdate", width: "auto" },
        { label: this.$t('Action'), prop: "Actions", width: "80" },
      ],
      arr1: [
        { label: '', prop: "isOrderWithIssue", width: "60" },
        { label: this.$t('Create Date'), prop: "orderCreationTime", width: "auto" },
        { label: 'ASN #', prop: "orderNo", width: "250" },
        { label: this.$t('Centre'), prop: "cneeName", width: "auto" },
        { label: this.$t('Customer'), prop: "datasourceType", width: "auto" },
        { label: 'ETA', prop: "totalGoodsValue", width: "auto" },
        { label: 'ATA', prop: "paymentStatus", width: "200" },
        { label: this.$t('Complete Date'), prop: "orderUpdateTime", width: "auto" },
        { label: this.$t('Status'), prop: "status", width: "auto" },
        { label: this.$t('Last Update'), prop: "lastUpdate", width: "auto" },
        { label: this.$t('Action'), prop: "Actions", width: "80" },
      ],
      filters: [
        // { text: this.$t('Show all'), value: "" },
        { text: this.$t('Create Date'), value: "orderCreationTime" },
        { text:  this.$t('Order')+'#', value: "orderNo" },
        { text: this.$t('Customer'), value: "cneeName" },
        { text: this.$t('Source'), value: "datasourceType" },
        { text: this.$t('Order Value'), value: "totalGoodsValue" },
        { text: this.$t('Payment Status'), value: "paymentStatus" },
        { text: this.$t('Last Update'), value: "orderUpdateTime" },
        { text: "Actions", value: "Actions" },
      ],
      statusList: [],
      status: '',
      isBusy: true,
      uploadText: 'Upload file here(drag the file or select from computer)',
      isDefault: true,
      uLoading: false,
      uSuccess: false,
      uFail: false,
      show: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      export1: true,
      export2: false,
      items: [
        {
          id :12345,
          exportTime: "2021-10-29 15:20:15",
          exportTypes: "all_orders",
          progress: "30",
          maxProgress:"255",
          reportUrl:null
        },
      ],
      fields: [
        { key: 'exportTypes', label: 'Export Name' },
        { key: 'progress', label: 'Export Progress', class: 'll-progress' },
        { key: 'action', label: this.$t('Action') }
      ],
      splitOrderList: [
        {
          itemName: "Mask",
          skuCode: "HHH-123",
          qty: 5,
          unitPrice: 15,
          currency: "HKD",
          origin: "HK",
          userDefinedField: "",
          batchNumber: "121-24",
          expiryDay: "2021-12-10",
          condition: "GOOD"
        }
      ],
      orderHeaders: [
        { label: this.$t('Item'), prop: "itemName", width: "auto" },
        { label: 'SKU', prop: "skuCode", width: "auto" },
        { label: 'QTY', prop: "qty", width: "auto" },
        { label: this.$t('Currency'), prop: "currency", width: "auto" },
        { label: this.$t('Origin'), prop: "origin", width: "auto" },
        { label: 'UDF', prop: "userDefinedField", width: "auto" },
        { label: this.$t('Batch No')+'.', prop: "batchNumber", width: "100" },
        { label: this.$t('Expiry Day'), prop: "expiryDay", width: "100" },
        { label: this.$t('Condition'), prop: "condition", width: "100" },
      ],
      orderNumber: null,
      splitBtn: true,
      isShowBtn: true,
      selected1: '',
      selected2: '',
      selected3: '',
      delOrder: null,
      isShowBtn1: true,
      pickerOptions: {
        shortcuts: [{
          text: 'Last 7 days',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 14 days',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 21 days',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 21);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'This month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      isResetBtn: true,
      condition: {
        orderNumber: null,
        clientCode: null,
        latestStatus: null,
        paymentStatus: null,
        dateRangeFrom: null,
        dateRangeTo: null,
        orderBy: 'id',
        sequence: 'desc',
        size: 50,
        page: 1,
      },
      listResult: null,
      checkAll: false,
      checkedFilter: [],
      isIndeterminate: true,
      paymentStatusList: [],
      salesId: null,
    }
  },
  computed: {},
  watch: {
    delOrder (val) {
      if (val) {
        this.isShowBtn1 = false
      } else {
        this.isShowBtn1 = true
      }
    },
  },
  created() {
  },
  mounted() {
    this.defaultDate()
    // this.getList()
    // this.queryStatus()
  },
  methods: {
    formatDate(date) {
      const year = date.getFullYear() + "-"
      const mouth = (date.getMonth()> 8 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1)) + "-"
      const day = (date.getDate()> 9 ? (date.getDate()) : "0" + (date.getDate()))
      const dateString = year + mouth + day
      return dateString
    },
    defaultDate() {
      let d1 = new Date((new Date).setDate((new Date).getDate() - 1))
      this.condition.dateRangeFrom = this.formatDate(d1) + ' 00:00:00'
      let d2 = new Date()
      this.condition.dateRangeTo = this.formatDate(d2) + ' 23:59:59'
      this.time = [this.condition.dateRangeFrom, this.condition.dateRangeTo]
    },
    onDateHandle(){
      const dd = this.time
      this.condition.dateRangeFrom = this.formatDate(dd[0]) + ' 00:00:00'
      this.condition.dateRangeTo = this.formatDate(dd[1]) + ' 23:59:59'
    },
    getList() {
      // console.log(this.condition)
      this.$http.post('/api/foms/order/list', this.condition).then(res=>{
        const info = res.data.data
        this.tableData = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.getPaymentStatus(info.content)
      })
    },
    getPaymentStatus(data) {
      let arr1 = []
      data.map(item=>{
        if (item.paymentStatus !== null) {
          arr1.push(item.paymentStatus)
        }
      })
      let obj = {}
      for (let i=0; i<arr1.length;i++){
        let value = arr1[i]
        if (!obj[value]){
          obj[value] = 1
        }else {
          obj[value]++
        }
      }
      let arr2 = []
      for (let j in obj) {
        arr2.push({
          name: j,
          count: obj[j]
        })
      }
      this.paymentStatusList = arr2
    },
    queryStatus(){
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'orderStatus1'}}).then(res => {
        this.statusList = res.data.data
      })
    },
    selStatus(d) {
      this.condition.latestStatus = d ? d.codeName : null
      this.status = d ? d.codeDescription : null
    },
    onStatusSearch(s){
      this.condition.paymentStatus = s
      this.getList()
    },
    filterTHeader(column) {
      var obj = {}
      let arr = []
      column.filter.map(i=>{
        obj[i] = true
      })
      this.arr1.map(i=>{
        if(obj[i.prop]) {
          arr.push(i)
        }
      })
      // console.log(arr)
      this.tableHeaders = arr
    },
    onSortChange() {},
    filterHandler() {
      console.log('11')
      return true
    },
    handleChangePage(page) {
      this.condition.page = page
      this.getList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getList()
    },
    uploadSuccess() {
      this.isDefault = false
      this.uLoading = false
      this.uSuccess = true
      this.uFail = false
    },
    uploadFail() {
      this.isDefault = false
      this.uLoading = false
      this.uSuccess = true
      this.uFail = false
    },
    uploadPro() {
      this.isDefault = false
      this.uLoading = true
      this.uSuccess = false
      this.uFail = false
    },
    exportHandle() {
      // if export success
      this.show1 = false
      this.export1 = false
      this.export2= true
    },
    handleSelectionChange(val) {
      if (val.length > 0) {
        this.splitBtn = false
      } else {
        this.splitBtn = true
      }
    },
    handleSelection(val) {
      this.listResult = val
      if (val.length > 0) {
        this.isShowBtn = false
      } else {
        this.isShowBtn = true
      }
    },
    handleCommand(d) {
      // console.log(d)
      if (d.orderNumber !== undefined) {
        this.orderNumber = d.orderNumber
      } else if (d === 'track') {
        this.$router.push({ name: 'track' })
      }
      this.salesId = d.id
    },
    onSearch() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      this.condition.clientCode = userData.organizationName
      console.log(this.time)
    },
    onReset() {
      this.condition.orderNumber = null
      this.condition.clientCode = null
      this.condition.latestStatus = null
      this.status = null
      this.time = ''
      this.condition.dateRangeFrom = ''
      this.condition.dateRangeTo = ''
    },
    handleCheckAllChange(val) {
      // this.checkedFilter = val ? this.filters : []
      const arr = []
      this.filters.map(i=>{
        arr.push(i.value)
      })
      this.checkedFilter = val ? arr : []
      this.isIndeterminate = false
    },
    handleChecked(value) {
      console.log('2', value)
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.filters.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.filters.length;
    },
    listenCreateModel(val) {
      this.show7 = val
      this.getList()
    },
    onOrderDetail(id) {
      this.$refs['modal-orderDetail'].show()
      this.salesId = id
    }
  },
}
</script>
<style scoped>
.ll-spaceEvenly{
  justify-content: space-evenly;
}
.ll-icon{
  width: 1.5em;
  height: 1.5em;
  fill: currentColor;
  overflow: hidden;
}
.ll-icon-small {
  width: 15px;
  height: 15px;
}
.ll-normal{
  width: 2em;
  height: 2em;
  fill: #28c76f;
}
.ll-normal1{
  width: 2em;
  height: 2em;
  fill: currentColor;
}
.ll-issue{
  width: 2em;
  height: 2em;
  fill: #ea5455;
}
.ll-badge{
  padding: 1rem;
}
.ll-issueBox{
  color: #ea5455;
}
.ll-flexEnd{
  justify-content: flex-end;
}
.ll-ulBox ul{
  list-style: none;
  padding-inline-start: 20px;
}
.ll-dropdown{
  width: 150px;
}
.ll-delicon{
  width: 5em;
  height: 5em;
  fill: #ea5455;
}
.ll-uploadIcon{
  width: 5em;
  height: 5em;
}
.ll-success{
  fill: #28c76f;
}
.ll-fail{
  fill: #ea5455;
}
.ll-uploadText{
  font-weight: bold;
  font-size: 24px;
}
.ll-box{
  justify-content: space-between;
}
.ll-count{
  padding: 5px 10px;
  border-radius: 50%;
  background-color: #fff;
}
.ll-datePicker{
  width: 100%;
  height: 34px;
}
.ll-filterBox{
  justify-content: space-between;
}
</style>
<style>
.ll-upload .el-upload-dragger{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ll-upload .el-upload{
  width: 100%;
}
</style>
