<template>
  <section>
    <div class="ll-headerBox mb-1">
      <span class="mr-1">ASN / test001</span>
      <b-badge
        pill
        class="ll-badge mr-1"
        variant="light-secondary"
      >
        created
      </b-badge>
      <span class="ll-floatRight">
        <b-button
          variant="outline-primary"
          class="btn"
          v-b-modal.modal-del
        >
          {{ $t('Cancel') }} ASN
        </b-button>
      </span>
    </div>
    <el-steps :active="1" class="mb-1" finish-status="finish">
      <el-step title="ASN Uploaded" description="2021-11-08 12:00:00">
        <template #icon>
          <svg aria-hidden="true" class="ll-icon">
            <use xlink:href="#icon-image-_2_"></use>
          </svg>
        </template>
      </el-step>
      <el-step title="Receiving" description="2021-11-08 12:00:00">
        <template #icon>
          <svg aria-hidden="true" class="ll-icon">
            <use xlink:href="#icon-product-delivery"></use>
          </svg>
        </template>
      </el-step>
      <el-step title="Finished">
        <template #icon>
          <feather-icon
            icon="CheckIcon"
            size="26"
          />
        </template>
      </el-step>
    </el-steps>
    <b-row>
      <b-col lg="12">
        <b-card class="mb-1 ll-cardHei">
          <b-card-header class="ll-cardHeader mb-1">
            <b-card-title>ASN {{ $t('Details') }}</b-card-title>
          </b-card-header>
          <b-card-body class="ll-cardBody">
            <b-row>
              <b-col>
                <label class="font-weight-bold">{{$t("Center")}}</label>
                <p>{{detailsInfo.ownerCode}}</p>
              </b-col>
              <b-col>
                <label class="font-weight-bold">ASN {{$t("Date")}}</label>
                <p>{{detailsInfo.warehouseCode}}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="font-weight-bold">{{$t("Client")}}</label>
                <p>{{detailsInfo.createdAt}}</p>
              </b-col>
              <b-col>
                <label class="font-weight-bold">{{$t("Is Rerurn ASN")}}</label>
                <p>{{detailsInfo.orderType}}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="font-weight-bold">{{$t("EAT At")}}</label>
                <p>{{detailsInfo.shipmentTerm}}</p>
              </b-col>
              <b-col>
                <label class="font-weight-bold">{{$t("ATA At")}}</label>
                <p>{{detailsInfo.logisticsCode}}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="font-weight-bold">{{$t("Is RequirePiece Scan")}}</label>
                <p>{{detailsInfo.paymentMethod}}</p>
              </b-col>
              <b-col>
                <label class="font-weight-bold">{{$t("Is All Over Receive")}}</label>
                <p>{{detailsInfo.totalGoodsValue}}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="font-weight-bold">{{$t("Is Weight Control")}}</label>
                <p>{{detailsInfo.goodsValueCurrency}}</p>
              </b-col>
              <b-col>
                <label class="font-weight-bold">{{$t("Remarks")}}</label>
                <p>{{detailsInfo.goodsValueCurrency}}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="font-weight-bold">{{$t("Estimate Quatity")}}</label>
                <p>{{detailsInfo.goodsValueCurrency}}</p>
              </b-col>
              <b-col>
                <label class="font-weight-bold">{{$t("Actual Quantity")}}</label>
                <p>{{detailsInfo.goodsValueCurrency}}</p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <b-card-header class="ll-cardHeader mb-1 ll-cardHead">
        <b-card-title>{{ $t('Items') }} </b-card-title>
      </b-card-header>
      <b-card-body class="ll-cardBody">
        <vxe-table
          border
          show-overflow
          keep-source
          ref="xTable"
          max-height="400"
          :data="detailsInfo.orderWmsDetailList"
          :edit-config="{ trigger: 'manual', mode: 'row', showStatus: true }"
          :empty-text="$t('No matching records found')"
        >
          <vxe-table-column field="itemName"
            :title="$t('Code')"
          >
          </vxe-table-column>
          <vxe-table-column field="skuCode"
            :title="$t('Barcade')"
          >
          </vxe-table-column>
          <vxe-table-column field="qty" :title="$t('Batch No')+'.'"
          >
          </vxe-table-column>
          <vxe-table-column field="unitPrice"
            :title="$t('Expiry Date')"
          >
          </vxe-table-column>
          <vxe-table-column field="currency"
            title="UDF"
          >
          </vxe-table-column>
          <vxe-table-column field="origin"
            :title="$t('Est Qty')"
          >
          </vxe-table-column>
          <vxe-table-column field="userDefinedField"
            :title="$t('Actual Qty')"
          >
          </vxe-table-column>
          <vxe-table-column field="batchNumber"
            :title="$t('PO No')+'.'" width="120"
          >
          </vxe-table-column>
          <vxe-table-column field="expiryDay"
            :title="$t('PO Date')" width="120"
          >
          </vxe-table-column>
          <vxe-table-column field="condition"
            :title="$t('Carton')" width="120"
          >
          </vxe-table-column>
          <vxe-table-column field="conditions"
            :title="$t('Pallet')" width="120"
          >
          </vxe-table-column>
        </vxe-table>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput,
  BButton,  BInputGroup, BModal, VBModal,
  BCardHeader, BCardTitle, BCardBody,
  BInputGroupAppend, BFormCheckbox, BBadge, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BModal,
    BInputGroupAppend,
    BFormCheckbox,
    BBadge,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    orderNumber: String,
    salesId: Number,
  },
  data() {
    return {
      item: [],
      isShow: false,
      isShow1: false,
      addItem: [
        {
          itemName: "Mask",
          skuCode: "HHH-123",
          availableQty: 15,
          qty: 5,
          unitPrice: 15,
          currency: "HKD",
          origin: "HK",
          userDefinedField: "1",
          batchNumber: "121-24",
          expiryDay: "2021-12-10",
          condition: "GOOD",
        },
      ],
      selectRecords: null,
      splitBtn1: true,
      orderHeaders1: [
        { label: this.$t('Item'), prop: "itemName", width: "auto" },
        { label: 'SKU', prop: "skuCode", width: "auto" },
        { label: 'QTY', prop: "qty", width: "auto" },
        { label: this.$t('Currency'), prop: "currency", width: "auto" },
        { label: this.$t('Origin'), prop: "origin", width: "auto" },
        { label: 'UDF', prop: "userDefinedField", width: "auto" },
        { label: this.$t('Batch No')+'.', prop: "batchNumber", width: "100" },
        { label: this.$t('Expiry Day'), prop: "expiryDay", width: "100" },
        { label: this.$t('Condition'), prop: "condition", width: "100" },
      ],
      splitOrderList1: [
        {
          itemName: "Mask",
          skuCode: "HHH-123",
          qty: 5,
          unitPrice: 15,
          currency: "HKD",
          origin: "HK",
          userDefinedField: "",
          batchNumber: "121-24",
          expiryDay: "2021-12-10",
          condition: "GOOD"
        }
      ],
      detailsInfo: {},
      isEdit: false,
      isEditItem: false,
    }
  },
  mounted(){
    // this.pushVal()
    // this.getDetails()
  },
  methods: {
    getDetails() {
      this.$http.get(`/api/foms/order/detail/${this.salesId}`).then(res=>{
        this.detailsInfo = res.data.data
      })
    },
    pushVal() {
      this.addItem.map(i=>{
        i.isDisable = true
      })
    },
    editRowEvent (row) {
      const $table = this.$refs.xTable
      $table.setActiveRow(row)
      this.isEditItem = true
    },
    removeEvent(row) {
      this.box = "";
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete .", {
        title: "Handle",
        size: "sm",
        okVariant: "primary",
        okTitle: "Yes",
        cancelTitle: "No",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then((value) => {
        if (value) {
          this.box = value;
          this.$refs.xTable.remove(row);
        }
      });
    },
    saveRowEvent () {
      const $table = this.$refs.xTable
      $table.clearActived().then(() => {
        setTimeout(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }, 300)
      })
    },
    cancelRowEvent (row) {
      const $table = this.$refs.xTable
      $table.clearActived().then(() => {
        // 还原行数据
        $table.revertData(row)
      })
    },
    getSelectionEvent (row) {
      if (row.records.length > 0) {
        row.records.map(i=>{
          i.isDisable = false
        })
        let arr = []
        arr = this.addItem.filter(x => !row.records.includes(x))
        arr.map(i=>{
          i.isDisable = true
        })
      } else {
        this.addItem.map(i=>{
          i.isDisable = true
        })
      }
      const $table = this.$refs.xTable1
      this.selectRecords = $table.getCheckboxRecords()
    },
    addItems() {
      this.item = this.selectRecords
      this.isShow = false
    },
    handleSelectionChange1(val) {
      if (val.length > 0) {
        this.splitBtn1 = false
      } else {
        this.splitBtn1 = true
      }
    },
    onDetailEdit() {
      this.isEdit = true
    },
    onDetailSave() {
      this.isEdit = false
      const obj = {}
      obj.id = this.detailsInfo.id
      obj.cneeName = this.detailsInfo.cneeName
      obj.cneeCity = this.detailsInfo.cneeCity
      obj.cneeDistrict = this.detailsInfo.cneeDistrict
      obj.cneeAddress = this.detailsInfo.cneeAddress
      obj.cneePhone = this.detailsInfo.cneePhone
      obj.senderName = this.detailsInfo.senderName
      obj.senderCity = this.detailsInfo.senderCity
      obj.senderDistrict = this.detailsInfo.senderDistrict
      obj.senderAddress = this.detailsInfo.senderAddress
      obj.senderPhone = this.detailsInfo.senderPhone
      this.$http.put('/api/foms/order/editDeliveryInfo', obj).then(res=>{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Save Success',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    onDetailCancel() {
      this.isEdit = false
    },
  },
}
</script>
<style scoped>
.ll-normal{
  width: 1.5em;
  height: 1.5em;
  fill: #28c76f;
}
.ll-issue{
  width: 1.5em;
  height: 1.5em;
  fill: #ea5455;
}
.ll-badge{
  padding: 1rem;
}
.ll-issueBox{
  color: #ea5455;
}
.ll-icon{
  width: 1.5em;
  height: 1.5em;
  fill: currentColor;
  overflow: hidden;
}
.ll-floatRight{
  float: right;
}
.ll-cardBody, .ll-cardHeader{
  padding: 0;
}
.ll-cardHei {
  height: 315px;
}
.ll-cardHei>.card-body{
  overflow-y: auto;
}
.ll-cardHead{
  justify-content: flex-start;
}
.ll-textAlignRight{
  text-align: right;
}
</style>
<style>
.el-step__icon{
  width: 50px;
  height: 50px;
  font-size: 20px;
  top: -10px;
}
[dir] .el-step__head.is-finish{
  border-color: #ed6d01;
}
.el-step__head.is-finish {
  color: #ed6d01;
}
.el-step__title.is-finish {
  color: #ed6d01;
}
.el-step__description.is-finish {
  color: #ed6d01;
}
</style>
